document.addEventListener("DOMContentLoaded", () => {
	const welcomeHost = document.querySelector('.welcome');

	if (welcomeHost) {
		const playButton = document.querySelector(".welcome__video-play-button");
		if (playButton) {
		playButton.onclick = function() {
			const video = this.nextElementSibling;
			const video_wrapper = this.parentElement;

			if (video.paused === true) {
				video.play();
				video.setAttribute('data-state','play');
				video.setAttribute('data-state-reload','false');
				video_wrapper.setAttribute('data-state','play');

				this.setAttribute('data-button-state','play');
				this.setAttribute('data-button-state-reload','false');
			} else {
				video.pause();
				video.setAttribute('data-state','pause');
				video_wrapper.setAttribute('data-state','pause');
				this.setAttribute('data-button-state','pause');
			}

			video.onended = (event) => {
				video.setAttribute('data-state','pause');
				video.setAttribute('data-state-reload','true');

				this.setAttribute('data-button-state','pause');
				this.setAttribute('data-button-state-reload','true');
			};

		}
		}
	}
})