document.addEventListener("DOMContentLoaded", () => {
	const testForm = document.querySelector("#pass-test-form");
	const popupBg = document.querySelector(".popup__bg");
	if (testForm) {
	testForm.addEventListener('submit', async (evt) => {
		evt.preventDefault();

		const address = `/test/check/`;
		const popup = popupBg.querySelector(".popup");

		openPopup(popup, popupBg)

		$.ajax({
			url: address,
			type: 'POST',
			data: new FormData(testForm),
			processData: false,
			contentType: false,
			statusCode: {
				422: function(response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupWrap = popupBg.querySelector(".popup__wrap");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => { closeTestPopup(popup, popupBg) })
					popupTitle.innerText = `Тест не пройден!`;
					popupWrap.innerHTML = response.responseText;
				},
				400: function(response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupWrap = popupBg.querySelector(".popup__wrap");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => { closeTestPopup(popup, popupBg) })
					popupTitle.innerText = `Тест не сдан!`;
					popupWrap.innerHTML = response.responseText;
					setTimeout(() => {
						testForm.reset();
					}, 1000)
				},
				200: function (response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => { closeTestPopup(popup, popupBg) })
					popupTitle.innerText = `Тест сдан!`;
					setTimeout(() => {
						location.reload();
					}, 5000)
				}
			}
		});
	})
	}
})

const popupBase = `<div class="popup active">
    <div class="popup__scroll">
      <div class="popup__inner">
        <div class="popup__close js-popup-close">
          <img src="/frontend_dist/assets/img/close.svg" alt="close popup"/>
        </div>
        <div class="popup__title"></div>
        <div class="popup__wrap"></div>
      </div>
    </div>
  </div>`
const popupLoader = `<div class="popup popup--loader"><img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></div>`;

function openPopup(popup, popupBg) {
	popup.classList.add("active");
	popupBg.classList.add("active");
}

function closeTestPopup(popup, popupBg) {
	popupBg.classList.remove("active");
	popup.classList.remove("active");

	setTimeout(() => {
		console.log('resetModal');
		popupBg.innerHTML = popupLoader
	}, 500)
}
