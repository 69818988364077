document.addEventListener("DOMContentLoaded", () => {
    const titleCourse = document.querySelectorAll('.js-clip');

    if(titleCourse.length === 0) return;

    titleCourse.forEach(title => {
        if(title.offsetHeight >= 96) {
            title.classList.add('courses__course-title--clip');
        }
    })
})