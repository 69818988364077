document.addEventListener("DOMContentLoaded", () => {
    const checkboxes = document.querySelectorAll('.js-checkbox');

    if(checkboxes.length === 0) return;

    checkboxes.forEach(check => {
        check.addEventListener('click', function() {
            let checked = this.querySelector('input[type="checkbox"]');
            checked.checked ? checked.checked = false : checked.checked = true;
        });
    });
})