document.addEventListener("DOMContentLoaded", () => {
	const hostRef = document.getElementById("courses");
	if (!hostRef) return;

	progressBar(hostRef);
})

function progressBar(hostRef) {
	const progressBarRef = hostRef.querySelector("#progress-bar");
	const circleProgressRef = hostRef.querySelector("#circle-progress");
	const pageDoneValue = progressBarRef.dataset.done;
	const pageTotalValue = progressBarRef.dataset.total;
	circleProgressRef.style.strokeDashoffset = 100 - (pageDoneValue / pageTotalValue * 100);
}
