document.addEventListener("DOMContentLoaded", () => {
	const hostRef = document.getElementById("nav");
	if (!hostRef) return;

	const userBtnRef = hostRef.querySelector("[data-user-btn]");
	if (userBtnRef) initUserBtn(hostRef, userBtnRef);
})

function initUserBtn(hostRef, userBtnRef) {
	const mainContentRef = hostRef.querySelector("[data-main-content]");
	const userContentRef = hostRef.querySelector("[data-user-content]");
	const menuTrigger = document.querySelector('.header__menu');

	userBtnRef.onclick = () => {
		mainContentRef.classList.toggle("hide");
		userContentRef.classList.toggle("hide");
	}

	menuTrigger.addEventListener("click", () => {
		mainContentRef.classList.remove("hide");
		userContentRef.classList.add("hide");
	})
}
