import { Navigation, Swiper, Grid } from 'swiper';
Swiper.use([Grid, Navigation]);

document.addEventListener("DOMContentLoaded", () => {
	const hostRef = document.getElementById("materials");
	if (!hostRef) return;

	initSwiper(hostRef);
})

function initSwiper(hostRef) {
	const sliderElem = hostRef.querySelector(".swiper");
	const btnPrev = hostRef.querySelector("[data-btn-prev]");
	const btnNext = hostRef.querySelector("[data-btn-next]");

	new Swiper(sliderElem, {
		slidesPerView: "auto",
		lazy: true,
		keyboard: true,
		spaceBetween: 20,
		loop: false,
		speed: 400,
		navigation: {
			prevEl: btnPrev,
			nextEl: btnNext
		},
	})
}
