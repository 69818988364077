document.addEventListener("DOMContentLoaded", () => {
    const radio = document.querySelectorAll('.js-radio');
    const radioGroup = document.querySelectorAll('.js-radio-group');

    if(radioGroup.length === 0) return;

    radio.forEach(rad => {
        rad.addEventListener('click', function() {
            let parent = this.parentElement.parentElement;
            let siblings = parent.querySelectorAll('input[type="radio"]');
            let checked = this.querySelector('input[type="radio"]');

            siblings.forEach(sib => {
                sib.checked = false;
            });

            checked.checked ? checked.checked = false : checked.checked = true;
        });
    });
})