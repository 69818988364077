// import Swiper, { Mousewheel } from "swiper";

// document.addEventListener("DOMContentLoaded", () => {
//     if(window.innerHeight >= 920) {
//         Swiper.use([Mousewheel]);

//         const fullpage = new Swiper('.fullpage', {
//             direction: 'vertical',
//             slidesPerView: 1,
//             loop: false,
//             speed: 700,
//             mousewheel: {
//                 forceToAxis: true,
//                 releaseOnEdges: true
//             },
//         });

//         fullpage.on('slidePrevTransitionStart', () => {
//             window.scrollTo(0, 0);
//         });
//     }
// })