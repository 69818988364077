import { checkExistParent } from "../lib/check-exist-parent";

document.addEventListener("DOMContentLoaded", () => {
	const headerRef = document.querySelector('.js-header');

	if (!headerRef) return;

	initFixed(headerRef);
	initUserMenu(headerRef);
})

function initFixed(headerRef) {
	window.addEventListener('scroll', function () {
		if (this.scrollY >= 198) {
			headerRef.classList.add('header--fixed')
		} else if (this.scrollY <= 50) {
			headerRef.classList.remove('header--fixed')
		}
	});
}

function initUserMenu(headerRef) {
	const userMenuBtnRef = headerRef.querySelector('[data-btn-user]');
	const userMenuRef = headerRef.querySelector('[data-user-menu]');

	if (userMenuBtnRef && userMenuRef) {
		userMenuBtnRef.onclick = () => {
			userMenuRef.classList.toggle('open');
			userMenuBtnRef.classList.toggle('open');
		}
	}

	document.addEventListener("click", (event) => {
		if (
			!checkExistParent(event.target, userMenuBtnRef) &&
			!checkExistParent(event.target, userMenuRef)
		) {
			if (userMenuRef) {
				userMenuRef.classList.remove('open');
			}
			if (userMenuBtnRef) {
				userMenuBtnRef.classList.remove('open');
			}
		}
	});
}
