document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll('a[href^="#"').forEach(link => {

        link.addEventListener('click', function(e) {
            e.preventDefault();

            let href = this.getAttribute('href').substring(1);

            const scrollTarget = document.getElementById(href);

            if (scrollTarget) {
                const topOffset = document.querySelector('.header').offsetHeight;
                const elementPosition = scrollTarget.getBoundingClientRect().top;
                const offsetPosition = elementPosition - topOffset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        });

    });

    document.querySelectorAll('.js-scroll').forEach(link => {
        link.addEventListener('click', function(e) {
            let href = this.getAttribute('href').substring(1);
            let currentHref = window.location.pathname;
            currentHref = currentHref.slice(1);

            if(href !== currentHref) return;

            e.preventDefault();

            let hash = this.getAttribute('data-hash');
            const scrollTarget = document.getElementById(hash);
            const topOffset = document.querySelector('.header').offsetHeight;
            const elementPosition = scrollTarget.getBoundingClientRect().top;
            const offsetPosition = elementPosition - topOffset;


            window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
            });
        });
    });
})
