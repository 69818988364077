document.addEventListener("DOMContentLoaded", () => {
	const bodyElem = document.body;
	const menuTrigger = document.querySelector('.header__menu');
	const navLinks = document.querySelectorAll('.nav__link');
	const popupButtons = document.querySelectorAll('.open-popup');
	const navDarker = document.querySelector('.nav-darker')

	function navToggle(trigger) {
		trigger.addEventListener('click', () => {
			let bodyState = bodyElem.getAttribute('data-state');
			bodyState === 'nav_open' ? bodyElem.dataset.state = '' : bodyElem.dataset.state = 'nav_open';
		});
	}

	if (menuTrigger) navToggle(menuTrigger);
	if (navDarker) navToggle(navDarker);
	navLinks.forEach(item => {
		navToggle(item);
	})
	popupButtons.forEach(item => {
		navToggle(item);
	})
})
