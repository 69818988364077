import { Pagination, Navigation, Swiper, Grid } from 'swiper';

Swiper.use([Grid, Navigation, Pagination]);

document.addEventListener("DOMContentLoaded", () => {
	const hostRef = document.getElementById("onboarding");
	if (!hostRef) return;

	initSwiper(hostRef);
})

function initSwiper(hostRef) {
	const sliderRef = hostRef.querySelector(".swiper");
	const btnNext = hostRef.querySelector("[data-btn-next]");
	const paginationRef = hostRef.querySelector('[data-pagination]');
	const linkStartTrainingRef = hostRef.querySelector('[data-link-start-training]');

	const swiper = new Swiper(sliderRef, {
		slidesPerView: 1,
		lazy: true,
		keyboard: true,
		spaceBetween: 1,
		loop: false,
		speed: 400,
		navigation: {
			nextEl: btnNext
		},
		pagination: {
			el: paginationRef,
			clickable: true
		},
	})

	swiper.on('slideChange', () => {
		const paginationBullet = Array.from(document.querySelectorAll('.swiper-pagination-bullet'));
		paginationBullet.forEach((item, index) => {
			if (index === swiper.activeIndex) {
				item.classList.add('swiper-pagination-bullet-active');
			} else {
				item.classList.remove('swiper-pagination-bullet-active');
			}
		})

		if (swiper.isEnd) {
			btnNext.classList.add("hide");
			linkStartTrainingRef.classList.remove("hide");
		} else {
			btnNext.classList.remove("hide");
			linkStartTrainingRef.classList.add("hide");
		}
	});
}
